import React from "react";
import styled from "styled-components";
import graduate from "../Assets/graduate.svg";
import clock from "../Assets/clock.svg";
import sessionsImage from "../Assets/sessions.svg";
import Button from "./Button";

const StyledCurso = styled.div`
  width: 280px;
  min-height: 233px;
  background-color: var(--sand);
  border-radius: 10px;
  opacity: 1;
  .big_image {
    display: none;
  }
  .button_bottom {
    padding: 5px 5px 5px 10px; 
    margin-top: 35px;
    width: 124px !important;
  }
  .container_info_top {
    display: flex;
    padding: 20px 5px;
    img {
      width: 65px;
      height: 100%;
    }
    .wrapper_title_info {
      font-family: "H Roman";
      letter-spacing: 0px;
      color: var(--black-smooth);
      h2 {
        font-size: 16px;
      }
      h3 {
        font-family: "S Italic"; //Cambiar fuente
        font-size: 16px;
      }
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: left;
      padding-left: 10px;
    }
  }
  .curso_info {
    display: flex;
    justify-content: space-between;
    .container_items_info {
      display: flex;
      flex-direction: row;
      align-items: center;

      p {
        font-family: "H Roman";
        font-size: 11px;
        color: #000000;
      }
      img {
        width: 15px;
      }
    }
  }

  @media (min-width: 360px) {
    min-width: 320px;
    width: 100%;
    max-width: 528px;
    .curso_info {
      .container_items_info {
        padding: 0 20px;
        p {
          font-size: 11px;
        }
      }
    }
  }
  @media (min-width: 526px) {
    .button_bottom {
      padding: 0px 20px;
      margin-top: 35px;
      width: 180px !important;
    }
  }
  @media (min-width: 728px) {
    width: 528px;
    .curso_info {
      display: flex;
      justify-content: space-around;
      .container_items_info {
        padding: 0 5px;
        p {
          font-size: 11px;
        }
      }
    }
  }
  @media (min-width: 992px) {
    .container_info_top {
      .wrapper_title_info {
        h2 {
          font-size: 22px;
        }
        h3 {
          font-size: 20px;
        }
      }
    }
    .curso_info {
      .container_items_info {
        p {
          font-size: 18px;
          margin-left: 15px;
          margin-right: 30px;
        }
        img {
          width: 20px;
        }
      }
    }
  }
  @media (min-width: 1200px) {
    width: 100%;
    max-width: 1000px;
    .principal_wrapper {
      display: flex;
      padding: 25px 15px 25px 10px;
      .big_image {
        display: block;
        img {
          width: 230px;
        }
      }
    }
    .container_all_info_data {
      margin-left: 60px;
      h2 {
        font-size: 25px;
      }
      .container_info_top {
        h2 {
          font-size: 25px;
        }
        h3 {
          font-size: 23px;
        }
        img {
          display: none;
        }
      }
      .curso_info {
        img {
          width: 25px;
        }
        p {
          font-size: 20px;
        }
      }
      .button_bottom {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
`;
export default function Cursoinfo(props) {
  const {
    imgCourse,
    titleCourse,
    subtitleCourse,
    time,
    sessions,
    initialStudents,
    maxStudents,
    link
  } = props;
  return (
    <StyledCurso>
      <div className="principal_wrapper">
        <div className="big_image">
          <img src={imgCourse} alt="icon_image" />
        </div>
        <div className="container_all_info_data">
          <div className="container_info_top">
            <img src={imgCourse} alt="icon_image" />
            <div className="wrapper_title_info">
              <h2>{titleCourse}</h2>
              <h3>{subtitleCourse}</h3>
            </div>
          </div>
          <div className="curso_info">
            <div className="container_items_info">
              <img src={clock} alt="icon_image" /> <p>{time} horas</p>
            </div>
            <div className="container_items_info">
              <img src={sessionsImage} alt="icon_image" />{" "}
              <p>{sessions} sesiones</p>
            </div>
            <div className="container_items_info">
              <img src={graduate} alt="icon_image" />{" "}
              <p>
                {initialStudents}-{maxStudents} Estudiantes
              </p>
            </div>
          </div>

          <div className="button_bottom">
            <a href={link} onClick={() => {console.log("Holaaa boton")}}>
            <Button buttonName={"Ver más"} image={false} />
            </a>
          </div>
        </div>
      </div>
    </StyledCurso>
  );
}
