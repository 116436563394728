import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import Homepage from "../components/homepage/index_home_page";
import BudaHomepage from "../images/homepage/BudaHomepage.webp"
import Tree from "../images/cursos/Tree.webp"

const IndexPage = () => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  return (
      <Layout>
        <Homepage 
                title='Gabriella Unikel'
                phrase='“La mayor libertad es la de ser dueños de nuestros pensamientos, palabras y acciones.”'
                firstImg={Tree}
                secondImg={BudaHomepage}
                description='En este curso veremos los fundamentos filosóficos del budismo, siempre enfocándonos en la aplicación concreta y personal de cada uno de ellos. Este curso no es religioso, sino filosófico-secular.'
                list={['Introducción al budismo y a la historia de Buda', 'El Karma y sus 12 leyes', 'Las tres Marcas de la Existencia', 'Las Cuatro Nobles Verdades', 'El Noble Óctuple Camino']}
            />
      </Layout>
  )
};

export default IndexPage;

export const Head = () => <title>Home Page</title>;
