import React, {useState, useEffect} from "react";
import styled from "styled-components";
import arrowRight from "../../Assets/arrow_right_slider.svg";
import arrowLeft from "../../Assets/arrow_left_slider.svg"
const StyledSlider = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 50px 20px 100px;
  .title {
    font-family: "S Bold";
    color: #2865c0;
  }
  .container_testimonio {
    background-color: var(--blue-aqua);
    min-width: 240px;
    max-width: 960px;
    height: 100%;
    min-height: 200px;
    border-radius: 24px;
    opacity: 1;
    padding: 20px;
  }
  .buttons_wrapper {
    display: flex;
    width: 30%;
    margin-top: 20px;
    justify-content: space-around;
    /* .button {
      background-color: white;
      border: none;
      color: white;
      padding: 20px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      margin: 4px 2px;
    } */
    button{
      cursor: pointer;
      border: none;
      background-color: transparent;
    }
    svg{
      fill: red;
    }
  }
`;
export default function Slider() {
  const [id, setId] = useState(0)
  const nextId = () => {
    setId((id) => {
        if (id < (contentArray.length - 1)) {
            id += 1;
        } else {
            id = 0;
        }
        return id;
    })
}

const prevId = () => {
    setId((id) => {
        if (id !== 0) {
            id -= 1;
        } else {
            id = (contentArray.length - 1);
        }
        return id;
    })
}

useEffect(() => {
    nextId(id)
})

useEffect(() => {
    prevId(id)
})

  return (
    <StyledSlider>
      <div className="title">
        <h1>Testimonios</h1>
      </div>
      <div className="container_testimonio">
            <div>
              <p>{contentArray[id].paragraph}</p>
            </div>
       
      </div>
      <div className="buttons_wrapper">
        <button onClick={prevId}><img src={arrowLeft} alt="arrow_icon" /></button>
        <button onClick={nextId}><img src={arrowRight} alt="arrow_icon" /></button>
       
      </div>
    </StyledSlider>
  );
}

const contentArray = [
  {
    paragraph: "Yo tomé el curso de “Viaje a Ixtlan” y fue una experiencia que marcó mi vida. Soy una persona que no lee muchos libros, pero al fusionar la lectura con las sesiones semana tras semana no solo me enamoré del libro, sino que también profundice tanto en el análisis que hoy en día aplico las enseñanzas de don Juan en mi vida diaria. Los cursos de Gaby son una experiencia liberadora, formativa, de mucho desarrollo personal y de crecimiento en la vida Me encantan y valen cada segundo",
  },
  {
    paragraph:
      "Los cursos de Gaby me hicieron aprender a ver la vida de una forma mucho más libre y mágica. Me recordaron que, a pesar de que solo soy una persona más en este inmenso universo, tengo el poder de elegir la forma en la que lo atravieso con mi existencia. En el curso de existencialismo aprendí lo responsable que soy de mí misma y de la energía que fluye a través de mí, mientras que en los cursos de budismo entendí la forma en la que, a pesar de vivir en el mundo que nos tocó vivir, existen aún un montón de herramientas que podemos aprender para atravesarlo de una forma cálida y amorosa con nosotros mismos. Eyal nunca había tenido tanta razón como el día en que me dijo que tomar estos cursos me cambiarían la forma en la que existo en esta vida.",
  },
  {
    paragraph:
      "He tenido la oportunidad de tomar alrededor de 5 cursos con Gabi. Cada uno ha sido bastante distinto al anterior, pero todos han sido maravillosos. Los cursos brindan una excelente herramienta para lidiar con las situaciones que se presentan en nuestras vidas. Pese a que los temas que de los que se habla son bastante profundos, e incluso un poco abstractos, utilizando videos, lecturas, y películas, Gabi sabe como hacer que estos conceptos se aterrizan de manera amena y divertida; para posteriormente poderlos explicar con un mayor grado de profundidad, haciendo que se te olvide que estás en una clase.",
  },
  {
    paragraph: "He tenido la oportunidad de tomar alrededor de 5 cursos con Gabi. Cada uno ha sido bastante distinto al anterior, pero todos han sido maravillosos. Los cursos brindan una excelente herramienta para lidiar con las situaciones que se presentan en nuestras vidas. Pese a que los temas que de los que se habla son bastante profundos, e incluso un poco abstractos, utilizando videos, lecturas, y películas, Gabi sabe como hacer que estos conceptos se aterrizan de manera amena y divertida; para posteriormente poderlos explicar con un mayor grado de profundidad, haciendo que se te olvide que estás en una clase."
  },
];
