import React, { useState } from "react";
import styled from "styled-components";
import arrow from "../Assets/down_arrow_white.svg";

const StyledDropdown = styled.div`
  background-color: var(--blue-strong);
  width: 280px;
  /* max-width: 1000px; */
  min-height: 76px;
  max-height: 50px;
  border-radius: 10px;
  opacity: 1;
  padding: 30px;
  p {
    font-family: "S Bold";
    letter-spacing: 0px;
    color: var(--white);
    opacity: 1;
  }
  .show_text {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    .test {
      transform: rotate(180deg);
    }
  }
  @media (min-width: 360px) {
    width: 100%;
    min-width: 340px;
  }
  @media (min-width: 728px) {
  
    width: 528px;
  }
  @media (min-width: 1200px) {
    width: 1000px;
  }
`;
export default function DropdownButton(props) {
  const { textButton } = props;
  const [showText, setShowText] = useState(false);
  return (
    <>
      <StyledDropdown>
        <div className="show_text" onClick={() => setShowText(!showText)}>
          <p>{textButton}</p>{" "}
          {showText ? (
            <img className="test" src={arrow} />
          ) : (
            <img src={arrow} />
          )}
        </div>
      </StyledDropdown>
    </>
  );
}
