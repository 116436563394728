import React from "react";
import styled from "styled-components";
import arrow from "../Assets/icn_arrow_right.svg";

const StyledButton = styled.div`
max-width: 325px;
  a {
    background-color: var(--blue-strong);
    width: 100%;
    max-width: 200px;
    font-family: "H Roman";
    /* height: 100%; */
    min-height: 48px;
    min-height: ${(props) => (props.secondary ? "32px" : null)};
    border-radius: 5px;
    opacity: 1;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    cursor: pointer;
    border: none;
    font-size: 16px;
    color: var(--white);
  }
  @media (min-width: 992px) {
    a{
      max-width: 325px;
    }
  }

`;

export default function Button(props) {
    const {buttonName, image, link} = props;
  return (
    <StyledButton>
      <a href={link}>{buttonName} {image === false ? null : <img src={arrow}/> }</a>
    </StyledButton>
  );
}
