import React from "react";
import "../../css/typography.css";
import useWindowSize from "../../hooks/useWindowSize";
import styled from "styled-components";
import CurvedDown from "../../images/curved/CurvedDown.webp";
import CurvedUp from "../../images/curved/CurvedUp.webp";
import Button from "../../Utils/Button";
import ButtonJoin from "../../Utils/ButtonJoin";
import Moon from "../../Assets/moon.svg";
import stars from "../../images/contact/stars_bottom.webp";
import stars_top from "../../images/contact/stars_top.webp";
import InfoBox from "./infoBox";
import Slider from "../testimonios/Slider";
import ContactBottom from "../contact/ContactBottom";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const FirstDiv = styled.div`
  background-image: url(${CurvedDown});
  background-size: cover;
  position: relative;
  padding: 8% 3% 15% 3%;
  z-index: 0;
  background-position-y: -65px;
  background-repeat: no-repeat;
  background-color: #efe8dc;
  .button {
    margin-top: 25px;
    margin-bottom: 45px;
    max-width: 325px;
  }
  .first-img {
    position: absolute;
    right: 0;
    top: 10%;
    width: 65%;
    z-index: -1;
    margin-top: 0px;
    max-height: 500px;
  }
  .container_title {
    max-width: 90%;
    .button_join {
      margin-top: 25px;
    }
  }
  .second-img {
    position: absolute;
    right: 5%;
    top: 10%;
    width: 26%;
  }

  .info-div {
    display: flex;
    justify-content: space-between;
    width: 35%;
    margin-top: 40px;
  }

  h1 {
    font-family: "S Bold";
    font-size: 40px;
    margin: 0;
    color: var(--gray-text);
    opacity: 1;
    margin-bottom: 10px;
  }
  .phrase {
    font-family: "H Roman";
    color: #484848;
    font-size: 20px;
    margin: 0 0 10px 0;
  }
  h2 {
    font-family: "H Roman";
    letter-spacing: 0px;
    font-size: 16px;
    color: var(--gray-text);
    opacity: 1;
    margin-top: 12px;
  }
  h3 {
    font-family: "H Bold";
    letter-spacing: 0px;
    font-size: 16px;
    color: var(--gray-text);
    opacity: 1;
    padding-top: 55px;
  }

  @media (min-width: 778px) {
    .container_title {
      max-width: 50%;
    }
  }
  @media (min-width: 550px) {
    .container_title {
      max-width: 65%;
    }
  }

  @media only screen and (min-width: 1800px) {
    h1 {
      font-size: 40px;
    }
    .h2 {
      font-size: 30px;
    }
  }
  @media only screen and (min-width: 2500px) {
    h1 {
      font-size: 45px;
    }
    .h2 {
      font-size: 35px;
    }
  }
  @media only screen and (min-width: 3500px) {
    h1 {
      font-size: 48px;
    }
    .h2 {
      font-size: 40px;
    }
  }

  @media only screen and (max-width: 1172px) {
    .info-div {
      width: 48%;
    }
  }
  @media only screen and (max-width: 890px) {
    padding: 8% 3% 25% 3%;

    .info-div {
      flex-wrap: wrap;
      width: 35%;
    }
    .first-img {
      top: 15%;
      width: 75%;
    }
  }
  @media only screen and (max-width: 730px) {
    padding: 10% 3% 25% 3%;
  }
  @media (max-width: 550px) {
    padding: 10% 3% 35% 3%;

    .second-img {
      top: 50%;
      right: 0.5%;
      width: 25%;
    }
  }
  @media only screen and (max-width: 551px) {
    padding: 10% 3% 45% 3%;

    .info-div {
      width: 80%;
    }
  }
  @media only screen and (max-width: 440px) {
    .info-div {
      width: 60%;
    }
  }
`;

const SecondDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--sand-2nd);
  padding: 0 10px 0 5%;
  font-family: "Regular";
  color: var(--black-1st);
  font-size: 17px;
  .title_about_me {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: -120px;
    p {
      letter-spacing: 0px;
      color: #2865c0;
      opacity: 1;
      font-size: 36px;
      font-family: "S Bold";
      margin-left: 15px;
    }
  }
  .stars_image_top {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    img {
      width: 130px;
    }
  }

  .content_about_me {
    width: 100%;
    p {
      letter-spacing: 0px;
      color: #191919;
      opacity: 1;
      font-family: "Regular";
      margin-bottom: 15px;
      font-size: 20px;
    }
  }
  .stars_bottom {
    display: flex;
    width: 100%;
    img {
      width: 125px;
    }
  }
  div {
    width: 50%;
    padding: 1%;
  }

  h3 {
    color: var(--blue-strong);
    font-size: 20px;
    font-family: "S Bold";
  }

  ol {
    padding: 0 0 0 15px;
  }

  ol li {
    margin-bottom: 10px;
  }

  p {
    width: 90%;
  }
  @media (min-width: 992px) {
    .stars_image_top {
      img {
        width: 160px;
      }
    }
    .content_about_me {
      p {
        font-family: 19px;
      }
    }
  }
  @media (min-width: 1200px) {
    .stars_image_top {
      img {
        width: 160px;
      }
    }
    .content_about_me {
      p {
        font-family: 22px;
      }
    }
  }
  @media only screen and (min-width: 1800px) {
    font-size: 25px;
    h3 {
      font-size: 30px;
    }
  }
  @media only screen and (min-width: 3500px) {
    font-size: 28px;
    h3 {
      font-size: 35px;
    }
  }
  @media only screen and (min-width: 4000px) {
    font-size: 33px;
    h3 {
      font-size: 40px;
    }
  }
`;

const ThirdDiv = styled.div`
  background-image: url(${CurvedUp});
  background-color: #efe8dc;
  background-size: cover;
  position: relative;
  padding: 10% 3% 5% 3%;
  z-index: 1; //estaba en -2
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
`;
const FourthDiv = styled.div`
  background-color: var(--sand-2nd);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
`;
const FiveDiv = styled.div`
  background-image: url(${CurvedUp});
  background-color: #efe8dc;
  background-size: cover;
  position: relative;
  padding: 10% 3% 5% 3%;
  z-index: 1; //estaba en -2
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
`;
const Curso = (props) => {
  const windowSize = useWindowSize();

  return (
    <>
      <FirstDiv>
        <div className="container_title">
          <h1>{props.title}</h1>
          <h2 className="phrase">{props.phrase}</h2>
        </div>
        <img src={props.firstImg} alt={props.firstAlt} className="first-img" />
        <img
          src={props.secondImg}
          alt={props.secondAlt}
          className="second-img"
        />
        <div className="button">
          <AnchorLink to="/#cursos-scroll" stripHash>
            <Button buttonName={"EXPLORAR CURSOS"} />
          </AnchorLink>
        </div>
        <div className="container_title">
          <h2>
            Únete a nuestro grupo de Whatsapp y mantente al tanto de todos los
            cursos y novedades.
          </h2>
          <div className="button_join">
            <ButtonJoin link={"https://api.whatsapp.com/send?phone=525573578566"}/>
          </div>
        </div>
      </FirstDiv>
      <SecondDiv>
        <div className="stars_image_top" id="sobre-mi-scroll">
          <img src={stars} alt="stars_image" />
        </div>
        <div className="title_about_me">
          <img src={Moon} alt="moon_icon" />
          <p>Sobre mí</p>
        </div>
        <div className="content_about_me">
          <p>
            Mi nombre es Gabriella Unikel y soy una persona apasionada por la
            lectura, las ideas y el conocimiento. Estudié la licenciatura en
            psicología, una maestría en literatura y un diplomado en filosofía.
            Sin embargo, más allá de mis estudios institucionales, descubrí en
            la educación mi mayor vocación y en la lectura mi mayor placer. En
            los años que he dedicado a la docencia, descubrí que cualquier tema
            puede ser el pretexto perfecto para ampliar nuestra mente,
            conocernos mejor a nosotres mismes y ser mejores representantes de
            la especie humana.
          </p>
          <p>
            Somos seres pensantes, así que debemos desarrollar nuestro
            pensamiento para lograr ser personas plenas, profundas e
            inspiradoras. Creo que esta es la única manera de construir un mundo
            en el que todes disfrutemos vivir.
          </p>
          <p>
            Espero podamos compartir un espacio de reflexión, crecimiento y
            pensamiento profundo, hablando de los temas que más te interesen.
          </p>
        </div>
        <div className="stars_bottom">
          <img src={stars_top} alt="stars_image" />
        </div>
      </SecondDiv>
      <ThirdDiv>
        <InfoBox />
      </ThirdDiv>
      <FourthDiv>
        <Slider />
      </FourthDiv>
      <FiveDiv>
        <ContactBottom />
      </FiveDiv>
    </>
  );
};

export default Curso;
