import React from "react";
import styled from "styled-components";
import blue_top from "../../Assets/blue_stars_top.webp";
import blue_bottom from "../../Assets/blue_stars_bottom.webp";
import CardContact from "../../Utils/CardContact";
const StyledContact = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .blue_stars_top {
    position: absolute;
    right: 0;
    img {
      width: 100px;
    }
  }
  .blue_stars_bottom {
    position: absolute;
    bottom: 0;
    left: 0%;
    z-index: -1;
    img {
      width: 200px;
    }
  }
  .title {
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--blue-strong);
  } 
  form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 33px;
    input {
      width: 100%;
      max-width: 1000px;
      margin-bottom: 18px;
      border: none;
      padding: 15px;
      background: white 0% 0% no-repeat padding-box;
      ::placeholder {
        color: #5c5c5c;
      }
      :last-child {
        max-width: 132px;
        height: 52px !important;
        font-family: "S Normal";
        display: flex;
        justify-content: center;
        margin: 18px auto 5px;
        background: var(--blue-strong) 0% 0% no-repeat padding-box;
        color: var(--white);
        cursor: pointer;
      }
    }
    textarea {
      width: 100%;
      max-width: 1000px;
      min-height: 152px;
      resize: none;
      background: white 0% 0% no-repeat padding-box;
      border: none;
      opacity: 1;
      padding: 15px;
      ::placeholder {
        color: var(--black-1st);
      }
    }
  }
  .card_contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    gap: 15px;
  }
  @media (min-width: 992px) {
    .blue_stars_bottom {
      display: none;
    }
    .blue_stars_bottom2 {
      display: block;
    }
    .wrapper_form_contact {
      margin-top: 33px;
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      /* .test {
        display: none;
      } */
      .test2 {
        display: block;
        margin-top: 33px;
        form {
          margin-top: 0px;
          min-width: 400px;
          input {
          }
          textarea {
            min-height: 250px;
          }
        }
      }
    }
    .card_contact {
      margin-top: 0;
      padding: 0;
    }
    .test {
      form {
        margin-top: 0;
      }
    }
  }
  @media (min-width: 1200px) {
    .wrapper_form_contact {
      .test2 {
        display: block;
        /* margin-top: 33px; */
        form {
          min-width: 700px;
        }
      }
    }
  }
  @media (min-width: 1400px) {
    .wrapper_form_contact {
      .test2 {
        form {
          min-width: 800px;
        }
      }
    }
  }
  @media (min-width: 1600px) {
    .wrapper_form_contact {
      .test2 {
        form {
          min-width: 900px;
        }
      }
    }
  }
  @media (min-width: 1800px) {
    .wrapper_form_contact {
      .test2 {
        display: block;
        /* margin-top: 33px; */
        form {
          min-width: 1100px;
        }
      }
    }
  }
`;
export default function ContactBottom() {
  const contactInfoArray = [
    {
      icon: require("../../Assets/call_icon.svg").default,
      textInfo: "+52 55 7357 8566",
    },
    {
      icon: require("../../Assets/email_icon.svg").default,
      textInfo: "unikelgabriella@gmail.com",
    },
    {
      icon: require("../../Assets/fb_white_icon.svg").default,
      textInfo: "Gabriella Unikel",
    },
  ];
  return (
    <StyledContact>
      <div className="blue_stars_top">
        <img src={blue_top} alt="stars_blue_img" />
      </div>
      <div className="title">
        <h1>Contacto</h1>
      </div>
      <div className="wrapper_form_contact">
        <div className="test test2">
          <form
            action="https://formspree.io/f/mlevlrrb"
            method="POST"
        >
            <input type="text" placeholder="Nombre" />
            <input type="reply_to" placeholder="Correo" />
            <textarea placeholder="Mensaje" />
            <input type="submit" value={"ENVIAR"}/>
          </form>
        </div>
        <div className="blue_stars_bottom">
          <img src={blue_bottom} alt="stars_blue_img" />
        </div>

        <div className="card_contact">
          {contactInfoArray.map((e, idx) => {
            return (
              <CardContact key={idx} icon={e.icon} textInfo={e.textInfo} />
            );
          })}
        </div>
      </div>

      <div className="blue_stars_bottom2">
        <img src={blue_bottom} alt="stars_blue_img" />
      </div>
    </StyledContact>
  );
}
