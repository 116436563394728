import React, { useState } from "react";
import "../../css/typography.css";
import styled from "styled-components";
import DropdownButton from "../../Utils/DropdownButton";
import CursoInfo from "../../Utils/Cursoinfo";

const InfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  font-family: "H Roman";
  margin-top: 120px;
  margin-bottom: 100px;
  .title {
    display: flex;
    justify-content: center;
    h1{
      font-family: "S Bold";
      color: #484848;;
    }
  }
  .drops {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-top: 35px;
  }
  img {
    width: 15px;
    margin-right: 10px;
  }
  .courses_map {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
  }
  @media only screen and (min-width: 1800px) {
    font-size: 25px;

    img {
      width: 20px;
    }
  }
  @media only screen and (min-width: 2500px) {
    font-size: 28px;
  }
  @media only screen and (min-width: 3500px) {
  }
  @media only screen and (min-width: 4000px) {
  }
`;

const InfoBox = () => {
  const [pensadores, setPensadores] = useState(false);
  const [books, setBooks] = useState(false);
  const [budismo, setBudismo] = useState(false);
  return (
    <InfoDiv>
      <div className="title" id='cursos-scroll'>
        <h1>Cursos</h1>
      </div>
      <div>
        <div className="drops" onClick={() => setBudismo(!budismo)}>
          <DropdownButton textButton={"Budismo"} />
        </div>
        {budismo ? (
          <div className="courses_map">
            {infoBudismo.map((e, idx) => {
              return (
                <CursoInfo
                  key={idx}
                  imgCourse={e.imgCourse}
                  titleCourse={e.titleCourse}
                  subtitleCourse={e.subtitleCourse}
                  time={e.time}
                  sessions={e.sessions}
                  initialStudents={e.initialStudents}
                  maxStudents={e.maxStudents}
                  link={e.link}
                />
              );
            })}
          </div>
        ) : null}
      </div>
      <div>
        <div className="drops" onClick={() => setPensadores(!pensadores)}>
          <DropdownButton textButton={"Ideas y Pensadores"} />
        </div>
        {pensadores ? (
          <div className="courses_map">
            {infoCursos.map((e, idx) => {
              return (
                <CursoInfo
                  key={idx}
                  imgCourse={e.imgCourse}
                  titleCourse={e.titleCourse}
                  subtitleCourse={e.subtitleCourse}
                  time={e.time}
                  sessions={e.sessions}
                  initialStudents={e.initialStudents}
                  maxStudents={e.maxStudents}
                  link={e.link}
                />
              );
            })}
          </div>
        ) : null}
      </div>

      <div>
        <div className="drops" onClick={() => setBooks(!books)}>
          <DropdownButton textButton={"Libros de Poder"} />
        </div>
        {books ? (
          <div className="courses_map">
            {infoBooks.map((e, idx) => {
              return (
                <CursoInfo
                  key={idx}
                  imgCourse={e.imgCourse}
                  titleCourse={e.titleCourse}
                  subtitleCourse={e.subtitleCourse}
                  time={e.time}
                  sessions={e.sessions}
                  initialStudents={e.initialStudents}
                  maxStudents={e.maxStudents}
                  link={e.link}
                />
              );
            })}
          </div>
        ) : null}
      </div>
    </InfoDiv>
  );
};

export default InfoBox;

const infoCursos = [
  {
    imgCourse: require("../../Assets/elephant.webp").default,
    titleCourse: "Existencialismo",
    subtitleCourse: "Fundamentos e ideas principales.",
    time: "8",
    sessions: "6",
    initialStudents: "3",
    maxStudents: "6",
    link: "/existencialismo"
  },
  {
    imgCourse: require("../../Assets/nietzsche.webp").default,
    titleCourse: "Friedrich Nietzsche",
    subtitleCourse: "Convertirnos en seres creadores",
    time: "8",
    sessions: "6",
    initialStudents: "3",
    maxStudents: "6",
    link: "/friedrich-nietzsche"
  },
  {
    imgCourse: require("../../Assets/old_man.webp").default,
    titleCourse: "La vida líquida",
    subtitleCourse: "La pérdida del sentido en sociedades modernas de consumo.",
    time: "6",
    sessions: "4",
    initialStudents: "3",
    maxStudents: "6",
    link: "/la-vida-liquida",
  },
  {
    imgCourse: require("../../Assets/albert_camus.webp").default,
    titleCourse: "Albert Camus",
    subtitleCourse: "Disfrutar la libertad del absurdo.",
    time: "8",
    sessions: "6",
    initialStudents: "3",
    maxStudents: "6",
    link: "/albert-camus",
  },
];

const infoBooks = [
  {
    imgCourse: require("../../Assets/nature.webp").default,
    titleCourse: "Así Habló Zaratustra,",
    subtitleCourse: "Parte 1 Análisis literario, psicológico y filosófico.",
    time: "8",
    sessions: "6",
    initialStudents: "3",
    maxStudents: "6",
    link: "/zaratustra",
  },
  {
    imgCourse: require("../../Assets/ixtlan.webp").default,
    titleCourse: "Viaje a Ixtlán",
    subtitleCourse:
      "Fortalecernos y responsabilizarnos para vivir con plenitud.",
    time: "8",
    sessions: "6",
    initialStudents: "3",
    maxStudents: "6",
    link: "/viaje-a-ixtlan",
  },
];

const infoBudismo = [
  {
    imgCourse: require("../../Assets/rocks_course.webp").default,
    titleCourse: "Fundamentos de la filosofía budista.",
    subtitleCourse:
      "Aceptando la realidad en sus propios términos.",
    time: "10",
    sessions: "8",
    initialStudents: "3",
    maxStudents: "6",
    link: "/fundamentos-filosofia-budista"
  },
  {
    imgCourse: require("../../Assets/plant_courses.webp").default,
    titleCourse: "Cómo nuestras emociones moldean nuestra percepción del mundo",
    subtitleCourse: "Descripcion detallada de nuestras percepciones y emociones",
    time: "5",
    sessions: "4",
    initialStudents: "3",
    maxStudents: "6",
    link: "/como-nuestras-emociones-moldean-la-percepcion-del-mundo"
  },
  {
    imgCourse: require("../../Assets/mandala_course.webp").default,
    titleCourse: "Estados mentales y factores mentales.",
    subtitleCourse: "Comprender nuestra mente para poderla transformar.",
    time: "10",
    sessions: "8",
    initialStudents: "3",
    maxStudents: "6",
    link: "/estados-y-factores-mentales",
  },
  {
    imgCourse: require("../../Assets/buda_course.webp").default,
    titleCourse: "Convertirnos en Budas.",
    subtitleCourse: "Despertar a una vida plenamente consciente.",
    time: "10",
    sessions: "8",
    initialStudents: "3",
    maxStudents: "6",
    link: "/convertirnos-en-budas"
  },
  {
    imgCourse: require("../../Assets/ola.webp").default,
    titleCourse: "Enojo y miedo.",
    subtitleCourse: "Aceptarlos, comprenderlos y transformarlos para crear relaciones saludables.",
    time: "4",
    sessions: "4",
    initialStudents: "3",
    maxStudents: "6",
    link: "/enojo-y-miedo"
  },
];