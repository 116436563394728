import React from 'react';
import styled from 'styled-components';

const StyledCardContact = styled.div`
width: 280px;
height: 56px;
background-color: var(--sand-2nd);
border-radius: 5px;
opacity: 1;
display: flex;
align-items: center;
padding: 0 25px;
p{
    font-size: 16px;
    margin-left: 26px;
}
img{
    width: 36px;
    height: 36px;
}
@media (min-width: 992px) {
    width: 383px;
    height: 128px;
}
`;
export default function CardContact(props) {
    const {icon, textInfo} = props;
  return (
    <StyledCardContact>
        <img src={icon} alt="icon_image" />
        <p>{textInfo}</p>
    </StyledCardContact>
  )
}
