import React from "react";
import styled from "styled-components";
import icn_whatsapp from "../Assets/icn_whatsapp_blue.svg";

const StyledButtonJoin = styled.div`
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 3px solid var(--blue-strong);
    width: 143px;
    min-height: 48px;
    background-color: transparent;
    border-radius: 5px;
    color: var(--blue-strong);
    text-transform: uppercase;
    opacity: 1;
    font-size: 16px;
    font-family: "S Bold";
    cursor: pointer;
    img{
        margin-left: 16px;
    }
  }
`;
export default function ButtonJoin(props) {
  const {link} = props;
  return (
    <StyledButtonJoin>
      <a href={link}>UNIRME <img src={icn_whatsapp}/></a>
    </StyledButtonJoin>
  );
}
